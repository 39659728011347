.p-float-label label {
    font-size: 13.5px;
}

.field-checkbox {
    font-size: 12.5px;
}

.p-checkbox .p-checkbox-box{
    border-radius: 0%;
}
.addition{
    margin: 0px;
    max-width: 100%;
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    padding-bottom: 10px;
}
.addition p{
    font-weight: bold;
}
.addbtn{
    position:absolute;
    right: 0px;
    top: -10px;
}
.headbgblue{
    font-size: 13px !important;
    border-bottom: 0px solid #ddd;
    padding: 10px 15px;
    background: #3c5a98;
    color: #fff;
    font-weight: bold !important;
    margin-bottom: 10px !important;
    position: relative;
}
.headbgblue span{
    position: absolute;
    right: 15px;
    top: 11px;
}
.imgbox_renter{
    max-width: 100%;
}
.vantype{
    font-size: 13px !important;
    font-weight: bold !important;
    text-align: center;
    position: relative;
}

.vandetails{
    width: 100%;
    margin-bottom: 20px;
}
.vandetails ul{
    list-style:none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}
.vandetails ul li{
    font-size: 12px;
    margin-right: 15px;
    display: block;
   text-align: center;
}
.vandetails ul li svg{
    font-size:20px;
}
.vandetails ul li span{
    display: block;
}
.vandetails ul li img{
    max-width:20px;
}
.boxmidright .sub{
    position: relative;
    color: #000;
    font-size: 20px;
    font-weight: 700;
}
.boxmidright .sub span{
   position: absolute;
    color: #3c5a98;
    font-weight: 700;
    font-size: 32px;
    right: 0;
}
.tbleforRenter{
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
}
.tbleforRenter tr td{
    padding:4px 0;
}
.textr{
    text-align: right;
    color: #3e5569;
    font-size: 17px;
}
.rowfont b{
    text-align: right;
    color: #3e5569;
    font-size: 13px;
}
.textl{
    text-align: left;
    color: #3e5569;
    font-size: 13px;  
}

.rowfont{
    color: #3e5569;
    font-size: 17px;
}
.rowfont2{
    color: #3e5569;
    font-size: 11px;
}
.red{
    color: red;
}
.blue{
    color: blue;
}
.text-center{
    justify-content: center;
}