.blue{
    color: #fff;
    font-size: 30px;
}
.iconbox{
 background:blue;
 height: 60px;
 display: flex;
 margin-right: 20px;
 align-items: center;
 justify-content: center;
}

.counttxt{
    font-size: 18px !important;
    color: blue;
}
.iconboxg{
    background:#35a997;
    height: 60px;
    display: flex;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
   }
   .counttxtg{
    font-size: 15px !important;
    color: #35a997;
    font-weight: bold;
}