
.logo{
	max-height: 24px;
}
.logobox{
	height: 60px;
    display: flex;
    align-items: center;
	background: #fff;
    padding: 0 28px;
}
.header_box {
	font-size: 13px;
	font-weight:300;
	padding:0px !important;
	line-height: 20px;
	border-bottom: 2px solid transparent;
	letter-spacing: -0.05px;
	margin: 10px 20px 0 0;

}

.header {
	padding: 0px !important;
}
.header_box_top {
	font-size: 10px;
	font-weight:normal;
	padding: 0px !important;
	margin-right: 30px;
	line-height: 20px;
	border-bottom: 2px solid transparent;
	padding-bottom: 0px;
	letter-spacing: -0.05px;

}
.loginImage{
	width: 100%;
}

.anchortag_space {
	margin: 0px;
	text-decoration: none;
}
.anchortag_space_top {
	margin: 0px;
	text-decoration: none;
}
.selected_item {
	border-color: #1156F1;
	color: #1156F1;
}

.mainmenu{
	width: 100%;
}
.mainmenu ul{
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
}
.mainmenu ul li{
	padding: 8px 10px;
	position: relative;
	z-index: 999;
}
.mainmenu ul>li>ul>li>ul,
.mainmenu ul>li>ul{
	display: none;
}
.mainmenu>ul>li:hover>ul{
	display: block;
	position: absolute;
	left: 0;
	top: 36px;
	min-width: 260px;
	background: #fff;
}
.mainmenu ul li ul li{
 border-bottom: 1px solid #ededed;
}
.mainmenu>ul>li>ul>li:hover>ul{
	display: block;
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 260px;
    background: #fff;
  
}
.rightarrow{
	left: 90%;
    position: absolute;
}

.mainmenu>ul>li>ul::after {
	bottom: 100%;
	left: 25px;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(237, 237, 237, 0);
	border-bottom-color: #ededed;
	border-width: 7px;
	margin-left: -7px;
}
.mainmenu ul li a{
	font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.05px;
	text-decoration: none;
}
.icon{
	vertical-align: middle;
}