.p-float-label label {
    font-size: 13.5px;
}

.field-checkbox {
    font-size: 12.5px;
}

.p-checkbox .p-checkbox-box{
    border-radius: 0%;
}
.addition{
    margin: 0px;
    max-width: 100%;
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    padding-bottom: 10px;
}
.addition p{
    font-weight: bold;
}
.addbtn{
    position:absolute;
    right: 0px;
    top: -10px;
}
.pagetitlemirror{
    position: relative;
}