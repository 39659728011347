.p-float-label label {
    font-size: 13.5px;
}

.field-checkbox {
    font-size: 12.5px;
}

.p-checkbox .p-checkbox-box{
    border-radius: 50%;
}
.addition{
    margin: 0px;
    max-width: 100%;
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    padding-bottom: 10px;
}
.addition p{
    font-weight: bold;
}
.addbtn{
    position:absolute;
    right: 0px;
    top: -10px;
}
.tablerate{
    width: 100%;
    border: 1px solid #ededed;
    border-collapse: collapse;
}
.tablerate th,
.tablerate td{
    border: 1px solid #ccc;
    padding: 8px;
}
.tablerate td .field{
    margin: 0;
}
.red{
    color: red;
    font-size: 12px;
}
.black-button {
    background-color: black !important;
    border-color: black !important;
    color: white !important;
}