.loginbox{
    width:400px;
    margin:20px;
    background: #fff;
    padding: 30px;
    box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
    position: relative;
}
.loginwrapper{
    width: 100%;
    height: 100vh;
    background: url("../../images/rezrevolution-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.loginwrapper::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: lch(0 0 0 / 0.28);
}
.logoadmin{
    text-align: center;
    font-size: 30px;
    margin: 0 0 10px 0;
    display: flex;
    justify-content: center;
}
.logoadmin img{
    max-height: 24px;
}
.logotext{
    text-align: center;
    font-size: 14px;
    margin-bottom:40px;
}
.user_icon{
    position: absolute;
    z-index: 9999;
    margin-left: 13px;
    margin-top: 9px;
}
