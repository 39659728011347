.p-float-label label {
    font-size: 13.5px;
}

.field-checkbox {
    font-size: 12.5px;
}

.p-checkbox .p-checkbox-box{
    border-radius: 0%;
}
.addition{
    margin: 0px;
    max-width: 100%;
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    padding-bottom: 10px;
}
.addition p{
    font-weight: bold;
}
.addbtn{
    position:absolute;
    right: 0px;
    top: -10px;
}
.valueoption{
    position: absolute;
    right: 10px;
}
.discount{
    position: absolute;
    left: 50%;
}
.fontsize{
    font-weight: bold;
    font-size: 18px;
}
.rootbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap; 
    gap: 30px; 
}

.boxloop {
    border: 1px solid #ccc;
    width: calc(100% - 15px); 
    box-sizing: border-box; 
    height: auto;
    margin-bottom: 20px;
}



.boxloop .heading{
    background: #ccc;
    margin-bottom:10px;
    color: #000;
    font-size: 16px;
    width: 100%;
    padding: 8px;
    font-weight: bold;
}

.listbox{
    position: relative;
    border-bottom: 1px solid #ccc;
    padding:12px !important;
    height: auto;
    display: flex;
}
.listbox .name{
 width:70%;
 cursor: pointer;
}
.listbox .name label{
    display: block;
}
.listbox .value{
    width: 70px;
    padding: 2px;
    background: #7cff80;
    border-radius: 6px;
    align-items: center;
    vertical-align: middle;
    display: flex;
    text-align: center;
    justify-content: center;
}
.listbox .radiobox{
    width: 17%;
    text-align: right; 
    cursor: pointer;
}
p.copycard{
    font-size: 12px;
    margin: 0 0 10px 0;
}