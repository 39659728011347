.container{
    min-height: 450px;
  }
  .chartdata{
    margin-top: 30px;
  }
  .cbox .dtitle{
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .cbox .dstitle{
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  .cbox .dsmitle{
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center;
  }
  .cbox{

  }