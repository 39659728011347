
.sidenav-container {
  height: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none; 
}

.sidenav-container::-webkit-scrollbar {
  display: none; 
}

.custom-panelmenu {
  flex: 1; 
}

.custom-panelmenu .p-panelmenu-content {
  border: none; 
}

.custom-panelmenu .p-panelmenu-header-link {
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: #ffffff;
}

.custom-panelmenu .p-panelmenu-header-link .p-menuitem-text {
  flex-grow: 1; 
  margin-right: 0.5rem;
}

.custom-panelmenu .p-panelmenu-header-link .pi.pi-chevron-right {
  margin-left: auto;
}

.custom-panelmenu .p-panelmenu-header-link:hover {
  background-color: #f1f1f1; 
}

.menu-item-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 15px; 
  border-radius: 4px; 
  font-size: 14px; 
  transition: background-color 0.3s ease; 
}