.footherheading{
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 13px;
    font-weight: bold;
}
.footherheadingright{
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 13px;
    font-weight: bold;
    text-align: right;
}
.footermenu ul li{
    padding: 0;
    margin-bottom: 10px;
}
.footermenu ul li a{
    text-decoration: none;
    color: #666;
    font-size: 13px;
}
.footeraddress p{
    padding: 0;
    margin-bottom: 10px;
    text-align: right;
    text-decoration: none;
    color: #666;
    font-size: 13px;
}
.copyright{
    background: #000;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
}
.cp{
    text-decoration: none;
    color: #fff;
}