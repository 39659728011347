body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eef5f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.field{
  width: 100%;
  margin-bottom: 30px;
}
#loginwrap .p-float-label label{ 
  left: 45px;
}
#loginwrap .p-float-label .p-inputwrapper-filled + label,
#loginwrap .p-float-label .p-filled + label{
  left: 0;
}
#loginwrap .p-fluid .p-inputtext{
  padding-left: 45px;
}
#loading {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  z-index: 99999;
  text-align: center;
  /* vertical-align: middle; */
  width: 100%;
  height: 100%;
  background: #e5eff1;
  line-height: 100vh;
}
#loadings img,
#loading img {
  width: 200px;
}
.comingsoon{
  max-width: 100%;
  text-align: center;
}
.comingsoon img{
 max-height: 100vh;
}
.card {
  background-color: initial;
  border: none !important;
}
.card table{
  font-size: 13px;
}
.notfound {
  text-align: center;
  font-size: 22px;
  padding-top: 190px;
  padding-bottom: 190px;
}
.ml-2{
  margin-left: 15px;
}
.p-error {
  color: #f44336 !important;
}
.p-float-label label#texeditor{
  top: -0.75rem;
}
.p-inputtextarea{
  width: 100%;
}
.maintitlediv{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.pagetitle{
  font-size: 19px;
    font-weight: bold;
    margin: 10px 0 45px 0;
}
.buttonarea{
  margin: 0;
    top: -10px;
    position: relative;
}
.buttonarea button{
  margin-left: 10px;
}
div.modsection{
  margin-top: 5px;
  border-top: 10px solid #eef5f9;
  padding-top: 15px;
  margin-right: -46px;
  width: 103%;
}